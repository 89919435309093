import 'classlist-polyfill';

const ATTRIBUTE_STEP = 'data-carousel-step';
const ATTRIBUTE_ACTIVE = 'data-carousel-active';
const CONTENT_ATTRIBUTE = 'data-carousel-content';
const COLOR_ATTRIBUTE = 'data-color';

export default class Carousel {
    constructor(props) {
        this.pause = this.pause.bind(this);
        this.resume = this.resume.bind(this);
        this.captureStepClick = this.captureStepClick.bind(this);

        this.element = props.element;
        this.stepElement = props.stepElement;
        this.hoverPauseElements = props.hoverPauseElements;
        this.intervalTime = props.intervalTime;
        this.delayTime = props.delayTime;
        this.stepChangedCallback = props.stepChangedCallback;
        this.index = 1;
        this.interval = null;
        this.timeout = null;
        this.listenForLinkClicks();
        this.listenForHoverStates();
        this.colorElements = document.querySelectorAll('[data-use-carousel-color]');
        this.backgroundColorElements = document.querySelectorAll(
            '[data-use-carousel-background-color]'
        );
    }

    start(customDelay) {
        const delay = customDelay || this.delayTime;

        this.goToStep(this.index);

        if (this.totalItems > 0) {
            this.timeout = setTimeout(() => {
                this.interval = setInterval(() => {
                    if (document.readyState === 'complete') {
                        this.next();
                    }
                }, this.intervalTime);
            }, delay);
        }
    }

    stop() {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        this.stopListeningForHoverStates();
    }

    pause() {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
    }

    resume() {
        this.start(0);
    }

    next() {
        this.goToStep(this.index + 1);
    }

    get carouselItems() {
        return Array.from(this.element.querySelectorAll(`[${CONTENT_ATTRIBUTE}]`));
    }

    get carouselLinks() {
        if (this.stepElement) {
            return Array.from(this.stepElement.querySelectorAll(`[${ATTRIBUTE_STEP}]`));
        } else {
            return [];
        }
    }

    get totalItems() {
        return this.carouselItems
            .map(item => item.getAttribute(ATTRIBUTE_STEP))
            .filter((item, idx, items) => items.indexOf(item) == idx).length;
    }

    goToStep(stepIndex) {
        this.index = Math.abs(stepIndex) % this.totalItems || this.totalItems;

        // Reset "active" state
        this.carouselItems.forEach(item => item.removeAttribute(ATTRIBUTE_ACTIVE));
        this.carouselLinks.forEach(link => link.removeAttribute(ATTRIBUTE_ACTIVE));

        // Set active on container
        this.element.setAttribute(ATTRIBUTE_STEP, this.index);

        // Set active link
        this.carouselLinks
            .filter(item => item.getAttribute(ATTRIBUTE_STEP) == this.index)
            .forEach(link => link.setAttribute(ATTRIBUTE_ACTIVE, true));

        // Set active item
        this.carouselItems
            .filter(item => item.getAttribute(ATTRIBUTE_STEP) == this.index)
            .forEach(item => {
                item.setAttribute(ATTRIBUTE_ACTIVE, true);

                const color = item.getAttribute(COLOR_ATTRIBUTE);
                if (color) {
                    this.setCurrentColor(color);
                }
            });

        this.stepChangedCallback(this.index);
    }

    setCurrentColor(color) {
        this.element.style.color = color;

        for (let el of this.colorElements) {
            el.style.color = color;
        }

        for (let el of this.backgroundColorElements) {
            el.style.backgroundColor = color;
        }
    }

    listenForLinkClicks() {
        this.carouselLinks.forEach(link => link.addEventListener('click', this.captureStepClick));
    }

    listenForHoverStates() {
        for (let element of this.hoverPauseElements) {
            element.addEventListener('mouseenter', this.pause);
            element.addEventListener('mouseleave', this.resume);
        }
    }

    stopListeningForHoverStates() {
        for (let element of this.hoverPauseElements) {
            element.removeEventListener('mouseenter', this.pause);
            element.removeEventListener('mouseleave', this.resume);
        }
    }

    captureStepClick(event) {
        let stepElement = event.target;
        let stepElementIndex = parseInt(stepElement.getAttribute(ATTRIBUTE_STEP));

        if (stepElementIndex) {
            this.stop();
            this.goToStep(stepElementIndex);
        }
    }

    static create({
        elementSelector = '[data-carousel]',
        stepElementSelector = '[data-carousel-steps]',
        intervalTime = 5000,
        delayTime = 0,
        hoverPauseElements = [],
        stepChangedCallback = () => {},
    } = {}) {
        const element = document.querySelector(elementSelector);
        const stepElement = element.querySelector(stepElementSelector);

        const carousel = new Carousel({
            element: element,
            stepElement: stepElement,
            intervalTime: intervalTime,
            delayTime: delayTime,
            hoverPauseElements: hoverPauseElements,
            stepChangedCallback: stepChangedCallback,
        });

        return carousel;
    }
}
