import Bugsnag from '../common/utils/Bugsnag';

let isFlashSaleActive = false;
let wasActiveCouponFetched = false;

function fetchActiveCoupon() {
    return fetch('/start/xhr/coupon')
        .then(response => {
            if (!response.ok) {
                Bugsnag.notify('Failed to fetch active coupon from /start/xhr/coupon');
                return;
            }

            wasActiveCouponFetched = true;

            return response.json();
        })
        .then(data => {
            return data && data.promotion;
        })
        .catch(error => {
            Bugsnag.notify(
                `Failed to fetch active coupon from /start/xhr/coupon: ${error.message}`
            );
        });
}

function showFlashSaleCallouts() {
    const elements = Array.from(document.querySelectorAll('[data-flash-sale-callout]'));
    elements.forEach(element => {
        element.removeAttribute('data-hidden');
    });

    const placeholders = Array.from(document.querySelectorAll('[data-flash-sale-placeholder]'));
    placeholders.forEach(element => {
        element.setAttribute('data-hidden', '');
    });
}

export function showFlashSaleElements() {
    if (!wasActiveCouponFetched) {
        fetchActiveCoupon().then(promotion => {
            isFlashSaleActive =
                promotion &&
                window.flashSaleConfig &&
                promotion.campaign === window.flashSaleConfig.name;

            if (isFlashSaleActive) {
                showFlashSaleCallouts();
            }
        });
    }

    if (isFlashSaleActive) {
        showFlashSaleCallouts();
    }
}
