import { Bugsnag } from '@bugsnag/js';

export function notify(err: Bugsnag.NotifiableError, opts?: Bugsnag.INotifyOpts) {
    const bugsnag = (window as any).bugsnagClient as Bugsnag.Client;

    opts = { severity: 'warning', ...opts };

    if (bugsnag && bugsnag.notify) {
        bugsnag.notify(err, opts);
    } else {
        if (opts.severity == 'warning') {
            console.warn(err, opts.metaData);
        } else {
            console.error(err, opts.metaData);
        }
    }
}

export function onReject(name: string) {
    return (value: any) => {
        let error: Bugsnag.NotifiableError;

        if (value instanceof Error) {
            error = value;
        } else if (typeof value === 'string') {
            error = { name, message: value };
        } else {
            error = { name, message: JSON.stringify(value) };
        }

        notify(error);
    };
}

export function handlePromise<T>(promise: Promise<T>, name: string) {
    promise.then(() => {}, onReject(name));
}

export default {
    notify,
    onReject,
    handlePromise,
};
