import { isSmallViewport } from 'common/utils/viewport-detection';

interface Options {
    threshold?: number;
    maxVelocity?: number;
}

export default class VelocityScrollable {
    private element: HTMLElement;
    private container: Element;
    private raf: Function;
    private velocity: number;
    private threshold: number;
    private maxVelocity: number;

    constructor(
        element: HTMLElement,
        container: HTMLElement,
        requestAnimationFrame: Function,
        options?: Options
    ) {
        this.element = element;
        this.container = container;
        this.raf = requestAnimationFrame;
        this.velocity = 0;
        this.threshold = options && options.threshold ? options.threshold : 300;
        this.maxVelocity = options && options.maxVelocity ? options.maxVelocity : 10;

        this.waitForMovement();
        this.startVelocityDetection();
    }

    private waitForMovement() {
        this.raf.call(null, () => {
            if (this.velocity !== 0) {
                this.move(this.velocity);
            }
            this.waitForMovement();
        });
    }

    private startVelocityDetection() {
        this.element.addEventListener(
            'mousemove',
            e => {
                const left = e.clientX;
                const right = this.container.clientWidth - e.clientX;

                if (isSmallViewport()) {
                    this.velocity = 0;
                } else {
                    this.velocity = this.calculateVelocity(left, right);
                }
            },
            false
        );

        this.element.addEventListener(
            'mouseleave',
            e => {
                this.velocity = 0;
            },
            false
        );
    }

    private calculateVelocity(left: number, right: number) {
        if (left < this.threshold) {
            return ((this.threshold - left) / this.threshold) * -this.maxVelocity;
        } else if (right < this.threshold) {
            return ((this.threshold - right) / this.threshold) * this.maxVelocity;
        } else {
            return 0;
        }
    }

    private move(offset: number) {
        this.element.scrollLeft += offset;
    }
}
