import { trackEvent } from '@99designs/common/utils/platform';
import debounce from 'common/utils/debounce';
import FullTextSearch from 'marketing/utils/FullTextSearch';

const FIELD_SELECTOR = '[data-category-search-field]';
const INPUT_ATTR = 'data-category-search-input';
const INPUT_SELECTOR = `[${INPUT_ATTR}]`;
const CLEAR_BUTTON_SELECTOR = '[data-category-search-clear-button]';
const MENU_SELECTOR = '[data-category-search-menu]';
const MENU_LIST_SELECTOR = '[data-category-search-menu-list]';
const CTA_SELECTOR = '[data-category-search-cta]';
const MAX_MENU_ITEMS = 5;

interface CategorySearchItem {
    title: string;
    parent: string;
    keywords: string;
    key: string;
}

class SearcherFactory {
    create() {
        const searchdocuments = this.readCategoriesData();

        const fallBackResult = searchdocuments
            .filter(doc => {
                return doc.key === 'other-design';
            })
            .pop();

        return new FullTextSearch(searchdocuments, this.getSearchConfig(), fallBackResult);
    }

    private getSearchConfig() {
        return [
            {
                name: 'title',
                weight: 0.8,
            },
            {
                name: 'parent',
                weight: 0.4,
            },
            {
                name: 'keywords',
                weight: 0.2,
            },
        ];
    }

    private readCategoriesData() {
        const categories: CategorySearchItem[] = [];

        (window as any).categories.forEach((category: any) => {
            categories.push({
                title: category.title,
                parent: category.parent_category_title,
                keywords: category.keywords.join(' '),
                key: category.key,
            });
        });

        return categories;
    }
}

export default class HomepageSearch {
    private menuItems: HTMLLIElement[];
    private selectedMenuItemIndex: number | null;
    private userEnteredSearchTerm: string;
    private debouncedSearchFunc: (arg: string) => void;

    constructor() {
        this.bindSearchInputKeyListener();
        this.bindClearSearchButton();
        this.bindClickOutsideSearchListener();
        this.bindCtaClickListener();

        this.menuItems = [];
        this.selectedMenuItemIndex = null;
        this.userEnteredSearchTerm = '';
        this.debouncedSearchFunc = this.createDebouncedSearchFunc();
    }

    get rootField(): Element {
        return document.querySelector(FIELD_SELECTOR)!;
    }
    get searchInput(): HTMLInputElement {
        return document.querySelector<HTMLInputElement>(INPUT_SELECTOR)!;
    }
    get clearButton(): HTMLButtonElement {
        return document.querySelector<HTMLButtonElement>(CLEAR_BUTTON_SELECTOR)!;
    }
    get menuInsertionNode(): Element {
        const menuElement = document.querySelector(MENU_SELECTOR)!;
        const menuInsertionNode = menuElement.querySelector(MENU_LIST_SELECTOR)!;
        return menuInsertionNode;
    }
    get cta(): HTMLAnchorElement {
        return document.querySelector<HTMLAnchorElement>(CTA_SELECTOR)!;
    }

    private createDebouncedSearchFunc() {
        const searcher = new SearcherFactory().create();

        return debounce((searchTerm: string) => {
            if (!!searchTerm) {
                const results = searcher.search(searchTerm).results;
                this.setCategorySearchMenu(results);
                this.trackSearchTerm(searchTerm, results);
            } else {
                this.resetSearch();
            }
        }, 300);
    }

    private bindSearchInputKeyListener() {
        this.searchInput.addEventListener('keyup', event => {
            switch (event.key) {
                case 'Enter':
                    if (this.selectedMenuItemIndex === null) {
                        return;
                    }

                    const selectedMenuItem = this.menuItems[this.selectedMenuItemIndex];
                    const menuItemLink = selectedMenuItem.querySelector('a')!;
                    if (menuItemLink) {
                        menuItemLink.click();
                    }
                    break;
                case 'Down':
                case 'ArrowDown':
                    this.handleArrowNavigation('down');
                    break;
                case 'Up':
                case 'ArrowUp':
                    this.handleArrowNavigation('up');
                    break;
                default: {
                    if (event.target) {
                        const searchTerm = (event.target as HTMLInputElement).value;
                        this.userEnteredSearchTerm = searchTerm;
                        this.debouncedSearchFunc(searchTerm);
                    }
                    break;
                }
            }
        });
    }

    private handleArrowNavigation(dir: 'up' | 'down') {
        if (this.menuItems.length === 0) {
            return;
        }

        let nextIndex = 0;
        const firstItemIndex = 0;
        const lastItemIndex = this.menuItems.length - 1;

        switch (dir) {
            case 'up':
                nextIndex =
                    this.selectedMenuItemIndex === null ||
                    this.selectedMenuItemIndex === firstItemIndex
                        ? lastItemIndex
                        : this.selectedMenuItemIndex - 1;
                break;
            case 'down':
                nextIndex =
                    this.selectedMenuItemIndex === null ||
                    this.selectedMenuItemIndex === lastItemIndex
                        ? firstItemIndex
                        : this.selectedMenuItemIndex + 1;
                break;
        }

        this.selectMenuItem(nextIndex);
    }

    private bindClearSearchButton() {
        this.clearButton.addEventListener('click', this.resetSearch.bind(this));
    }

    private bindClickOutsideSearchListener() {
        window.addEventListener('click', event => {
            if (event.target && !(event.target as Element).hasAttribute(INPUT_ATTR)) {
                this.hideSearchResults();
            }
        });
    }

    private bindCtaClickListener() {
        this.cta.addEventListener('click', () => {
            if (this.userEnteredSearchTerm === '') {
                trackEvent('Clicked CTA on Home Page', {
                    cta_location: 'hero-primary',
                });
            } else {
                const urls = this.cta.href.split('/');
                const categoryKey = urls[urls.length - 1];
                this.trackSelectedSearchResult(categoryKey);
            }
        });
    }

    private normalisedSearchItems(searchItems: CategorySearchItem[]): CategorySearchItem[] {
        // Hack to add category and act on it like a search result.
        const normalisedArray = searchItems.slice(0, MAX_MENU_ITEMS);

        normalisedArray.push({
            key: 'categories',
            title: __('See all design categories'),
            keywords: '',
            parent: '',
        });
        return normalisedArray;
    }

    private setCategorySearchMenu(searchItems: CategorySearchItem[]) {
        this.cleanupCategorySearchMenu();

        this.normalisedSearchItems(searchItems).forEach((searchItem, index) => {
            const menuItem = this.createMenuItemElement({
                url: `/${searchItem.key}`,
                title: searchItem.title,
            });

            menuItem.id = `category-search-item-${searchItem.key}`;

            menuItem.addEventListener('click', () => {
                this.trackSelectedSearchResult(searchItem.key);
                this.hideSearchResults.bind(this);
            });
            menuItem.addEventListener('mouseover', () => {
                this.selectMenuItem(index);
            });

            this.menuItems.push(menuItem);
            this.menuInsertionNode.appendChild(menuItem);
        });

        this.menuInsertionNode.insertBefore(
            this.createMenuItemSeparator(),
            this.menuItems[this.menuItems.length - 1]
        );

        this.selectMenuItem(null);
        this.showSearchResults();
    }

    private selectMenuItem(index: number | null) {
        this.resetAriaSelected();

        if (index !== null) {
            const selectedMenuItem = this.menuItems[index];
            this.toggleHighlight({ menuItem: selectedMenuItem, highlighted: true });
            selectedMenuItem.setAttribute('aria-selected', 'true');
        }

        this.selectedMenuItemIndex = index;
    }

    private resetAriaSelected() {
        this.menuItems.forEach(menuItem => {
            this.toggleHighlight({ menuItem: menuItem, highlighted: false });
            menuItem.setAttribute('aria-selected', 'false');
        });
    }

    private toggleHighlight({
        menuItem,
        highlighted,
    }: {
        menuItem: HTMLLIElement;
        highlighted: boolean;
    }) {
        if (highlighted) {
            menuItem.setAttribute('data-selected', '');
        } else {
            menuItem.removeAttribute('data-selected');
        }
    }

    private cleanupCategorySearchMenu() {
        while (this.menuInsertionNode.firstChild) {
            this.menuInsertionNode.removeChild(this.menuInsertionNode.firstChild);
        }
        this.menuItems = [];
    }

    private resetSearch() {
        this.hideSearchResults();
        this.userEnteredSearchTerm = '';
        this.selectedMenuItemIndex = null;
        this.searchInput.value = '';
        this.cleanupCategorySearchMenu();
        this.cta.href = '/categories';
    }

    private showSearchResults() {
        this.rootField.setAttribute('data-menu-active', '');
    }

    private hideSearchResults() {
        this.rootField.removeAttribute('data-menu-active');
    }

    private createMenuItemElement({ url, title }: { url: string; title: string }): HTMLLIElement {
        const li = document.createElement('li');
        li.classList.add('menu__item__action');
        li.setAttribute('role', 'option');

        const span = document.createElement('span');
        span.classList.add('menu__item__action__label');
        span.textContent = title;

        const a = document.createElement('a');
        a.classList.add('menu__item__action__link');
        a.href = url;

        a.appendChild(span);
        li.appendChild(a);

        return li;
    }

    private createMenuItemSeparator(): HTMLDivElement {
        const separator = document.createElement('div');
        separator.classList.add('menu__item--separator');
        separator.style.height = '2px'; // tristan made me do it
        return separator;
    }

    private trackSearchTerm(searchTerm: string, searchResults: CategorySearchItem[]) {
        trackEvent('Performed Search', {
            location: 'homepage',
            searchTerm: searchTerm,
            searchTarget: 'design category',
            noOfSearchResults: searchResults.length,
        });
    }

    private trackSelectedSearchResult(categoryKey: string) {
        trackEvent('Selected Search Result', {
            location: 'homepage',
            search_term: this.userEnteredSearchTerm,
            selected_category: categoryKey,
        });
    }
}
