export default class NumberCounter {
    ANIMATION_DELAY = 2000;
    DIGIT_STAGGER_DELAY = 300;

    constructor() {
        this.activeAnimations = 0;
        this.container = document.querySelector('[data-number-counter-number]');
        this.insertComponents();
        window.requestAnimationFrame(this.loopAnimation.bind(this));
    }

    getSourceNumber() {
        const sourceNumEl = document.querySelector('[data-number-counter-number]');
        return sourceNumEl.getAttribute('data-number-counter-number');
    }

    splitSourceNumber() {
        const sourceNumber = this.getSourceNumber();
        return sourceNumber.split('');
    }

    createCounterComponents() {
        const sourceNumberChars = this.splitSourceNumber();
        return sourceNumberChars.map(char => {
            return this.createCounterComponent(char);
        });
    }

    createCounterComponent(char) {
        const num = parseInt(char, 10);
        if (num) {
            return this.createNumberComponent(num);
        } else {
            return this.createCharComponent(char);
        }
    }

    createNumberComponent(num) {
        const numberDigit = document.createElement('span');
        numberDigit.classList.add('number-counter__digit');

        const numberDigitTrack = document.createElement('span');
        numberDigitTrack.classList.add('number-counter__digit__track');
        numberDigitTrack.setAttribute('data-number-counter-digit-track', '');

        const numberDigitCurrenValue = document.createElement('span');
        numberDigitCurrenValue.classList.add('number-counter__digit__track__value');
        numberDigitCurrenValue.setAttribute('data-number-counter-current', '');
        numberDigitCurrenValue.innerText = num;

        const numberDigitNextValue = document.createElement('span');
        numberDigitNextValue.classList.add('number-counter__digit__track__value');
        numberDigitNextValue.setAttribute('data-number-counter-next', '');
        numberDigitNextValue.innerText = this.calculateNextNumber(num);

        numberDigit.appendChild(numberDigitTrack);

        numberDigitTrack.appendChild(numberDigitCurrenValue);
        numberDigitTrack.appendChild(numberDigitNextValue);

        return numberDigit;
    }

    calculateNextNumber(num) {
        const nextNum = num + 1;
        if (nextNum === 10) {
            return 0;
        }
        return nextNum;
    }

    createCharComponent(char) {
        const charContainer = document.createElement('span');
        charContainer.innerText = char;
        return charContainer;
    }

    insertComponents() {
        const numberCounter = document.createElement('span');
        numberCounter.classList.add('number-counter');

        const components = this.createCounterComponents();
        components.forEach(component => {
            numberCounter.appendChild(component);
        });

        this.container.appendChild(numberCounter);
    }

    animate() {
        const digits = Array.from(document.querySelectorAll('[data-number-counter-digit-track]'));
        digits.reverse();

        let willOverflow = true;
        for (let i = 0; i < digits.length; ++i) {
            const digit = digits[i];

            if (!willOverflow) {
                break;
            }

            this.activateAnimation(digit, i);

            willOverflow = digit.querySelector('[data-number-counter-next]').innerText == 0;

            this.bindAnimationEndOnce(digit);
        }

        this.awaitingAnimation = false;
    }

    activateAnimation(digit, position) {
        const isAnimatingFirstDigit = position === 0;
        if (isAnimatingFirstDigit) {
            digit.setAttribute('data-active', '');
        } else {
            setTimeout(() => {
                digit.setAttribute('data-active', '');
            }, this.DIGIT_STAGGER_DELAY * position);
        }
        this.activeAnimations += 1;
    }

    bindAnimationEndOnce(digit) {
        const doAnimationEndOnce = () => {
            digit.removeAttribute('data-active');
            this.incrementDigitValues(digit);
            this.activeAnimations -= 1;
            digit.removeEventListener('animationend', doAnimationEndOnce);
        };
        digit.addEventListener('animationend', doAnimationEndOnce);
    }

    incrementDigitValues(digit) {
        const current = digit.querySelector('[data-number-counter-current]');
        const next = digit.querySelector('[data-number-counter-next]');
        current.innerText = next.innerText;
        next.innerText = this.calculateNextNumber(parseInt(next.innerText, 10));
    }

    isAnimating() {
        return this.activeAnimations > 0 || this.awaitingAnimation;
    }

    loopAnimation() {
        if (!this.isAnimating()) {
            this.awaitingAnimation = true;
            setTimeout(this.animate.bind(this), this.ANIMATION_DELAY);
        }
        window.requestAnimationFrame(this.loopAnimation.bind(this));
    }
}
