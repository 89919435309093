import elasticLunr from 'elasticlunr';
import Fuse from 'fuse.js';
import uniq from 'lodash/uniq';

export default class FullTextSearch {
    constructor(indexItems, searchKeys, fallbackResult = null) {
        this.indexItems = indexItems;
        this.searchKeys = searchKeys;
        this.fallbackResult = fallbackResult;

        this.naturalLanguageSearcher = this.createLunrIndex();
        this.fuzzySearcher = this.createFuseIndex();
    }

    createFuseIndex() {
        return new Fuse(this.indexItems, {
            keys: this.searchKeys,
            shouldSort: true,
            threshold: 0.3,
            minMatchCharLength: 3,
            include: ['matches'],
        });
    }

    createLunrIndex() {
        const searchKeys = this.searchKeys;
        const index = elasticLunr(function() {
            this.setRef('id');
            searchKeys.forEach(key => {
                this.addField(key.name);
            });
        });

        this.indexItems.forEach((item, idx) => {
            const docKeys = this.searchKeys.reduce((acc, val) => {
                acc[val.name] = item[val.name];
                return acc;
            }, {});

            index.addDoc(Object.assign({ id: idx }, docKeys));
        });

        return index;
    }

    getNaturalLanguageResults(searchTerm) {
        const lunrOptions = {
            expand: true,
            bool: 'OR',
            fields: this.searchKeys.reduce((acc, val) => {
                acc[val.name] = { boost: val.weight };
                return acc;
            }, {}),
        };

        const results = this.naturalLanguageSearcher.search(searchTerm, lunrOptions).map(result => {
            return this.indexItems[result.ref];
        });

        const searchTerms = searchTerm
            .trim()
            .split(' ')
            .filter(String);

        return {
            results: results,
            matchingTerms: [
                ...searchTerms,
                ...searchTerms.map(string => elasticLunr.stemmer(string)),
            ],
        };
    }

    getFuzzyResults(searchTerm) {
        const results = [];
        const matchingTerms = [];

        this.fuzzySearcher.search(searchTerm).forEach(result => {
            results.push(result.item);

            result.matches.forEach(match => {
                if (match && match.indices.length > 0) {
                    const matchingTerm = result.item[match.key].slice(
                        match.indices[0][0],
                        match.indices[0][1]
                    );

                    if (matchingTerms.indexOf(matchingTerm) === -1) {
                        matchingTerms.push(matchingTerm);
                    }
                }
            });
        });

        return {
            results: results,
            matchingTerms: matchingTerms,
        };
    }

    combineResults(resultSetOne, resultSetTwo) {
        resultSetTwo.forEach(result => {
            if (resultSetOne.indexOf(result) === -1) {
                resultSetOne.push(result);
            }
        });

        if (this.fallbackResult && resultSetOne.length === 0) {
            resultSetOne.push(this.fallbackResult);
        }

        return resultSetOne;
    }

    combineMatchingTerms(lunrMatchingTerms, fuseMatchingTerms) {
        return uniq([...lunrMatchingTerms, ...fuseMatchingTerms]);
    }

    search(searchTerm) {
        const naturalLanguageResults = this.getNaturalLanguageResults(searchTerm);
        const fuzzyResults = this.getFuzzyResults(searchTerm);

        return {
            results: this.combineResults(naturalLanguageResults.results, fuzzyResults.results),

            // use matchingTerms for highlighting purposes
            matchingTerms: this.combineMatchingTerms(
                naturalLanguageResults.matchingTerms,
                fuzzyResults.matchingTerms
            ),
        };
    }
}
