import { trackEvent, trackLink } from '@99designs/common/utils/platform';
import Carousel from './components/Carousel';
import SlideCarousel from './components/slide-carousel';
import NumberCounter from './components/NumberCounter';
import HomepageSearch from './components/HomepageSearch';
import createScrollableElements, { scrollOnHover } from './components/scrollable';
import StickyBar from './components/StickyBar';
import { showFlashSaleElements } from './flashSale';

document.addEventListener('DOMContentLoaded', () => {
    initHeroCarousel();
    initProSectionCarousel();
    initScrollables();

    SlideCarousel.create({ stepClickEventMessage: 'Clicked Carousel Trigger on Home Page' });

    new HomepageSearch();
    new NumberCounter();
    new StickyBar();

    showFlashSaleElements();

    // To clean up once we resolve the experiment
    trackLegacyEvents();

    // To be used for tracking once we resolve the experiment
    assignTracking();
});

function trackLegacyEvents() {
    const designerPortfolioCTA = document.querySelector('[data-home-cta]');
    const searchTags = document.querySelectorAll('[data-search-tag]');

    if (designerPortfolioCTA) {
        trackLink(designerPortfolioCTA, 'Browse designer portfolios link on home page clicked');
    }

    if (searchTags) {
        Array.from(searchTags).forEach(tag => {
            trackLink(tag, 'Search tag in home page hero clicked', {
                category: tag.getAttribute('data-search-tag-category'),
            });
        });
    }

    trackWithLocation(
        '[data-homepage-cta]',
        'data-homepage-cta-location',
        'Clicked CTA on Home Page'
    );

    trackWithLocation(
        '[data-blog-link]',
        'data-blog-link-location',
        'Clicked Blog Link on Home Page'
    );
}

function initProSectionCarousel() {
    if (document.querySelector('[data-carousel="pro-section-carousel"]')) {
        Carousel.create({
            elementSelector: '[data-carousel="pro-section-carousel"]',
            stepElementSelector: '[data-carousel="pro-section-carousel"] [data-carousel-steps]',
        }).start();
    }
}

function initScrollables() {
    createScrollableElements();
    scrollOnHover('[data-scrollable]');
}

/**
 * This is an experimental function that allow automatic
 * binding of tracking events.
 *
 * Example to track event:
 * <div
 *   data-track-event="Clicked CTA button"
 *   data-track-event-cta-location="cta-banner"
 *   data-track-event-userId="anonymous"
 * />
 *
 * Example to track link:
 * <a
 *   data-track-link="Clicked CTA link"
 *   data-track-link-cta-location="cta-banner"
 *   href="#"
 * >click me</a>
 */
function assignTracking() {
    const DATA_TRACK_LINK = 'data-track-link';
    const DATA_TRACK_EVENT = 'data-track-event';

    const getEventDetails = (element, eventType) => {
        const prefix = `${eventType}-`;
        const prefixLength = prefix.length;
        const args = element.getAttributeNames().reduce((previousValue, attributeName) => {
            if (attributeName.startsWith(prefix)) {
                const prop = attributeName.slice(prefixLength).replace(/-/, '_');

                return {
                    ...previousValue,
                    [prop]: element.getAttribute(attributeName),
                };
            }
            return previousValue;
        }, {});

        return {
            event: element.getAttribute(eventType) || null,
            args,
        };
    };

    const trackByType = type => {
        Array.from(document.querySelectorAll(`[${type}]`)).forEach(element => {
            const { event, args } = getEventDetails(element, type);
            if (!event) {
                return;
            }

            if (type === DATA_TRACK_LINK) {
                trackLink(element, event, args);
            } else if (type === DATA_TRACK_EVENT) {
                element.addEventListener('click', () => trackEvent(event, args));
            }
        });
    };

    [DATA_TRACK_LINK, DATA_TRACK_EVENT].forEach(trackByType);
}

function initHeroCarousel() {
    Carousel.create({
        elementSelector: '[data-carousel="hero-carousel"]',
        stepElementSelector: '[data-carousel="hero-carousel"] [data-carousel-steps]',
    }).start();
}

/* Legacy helpers to clean up once experiment roles out */

function trackWithLocation(selector, locationAttr, message) {
    Array.from(document.querySelectorAll(selector)).forEach(item => {
        const location = item.getAttribute(locationAttr);
        trackLink(item, message, { cta_location: location });
    });
}
