import { trackEvent } from '@99designs/common/utils/platform';
import { isSmallViewport, isMediumViewport } from 'common/utils/viewport-detection';
import { getOffset } from 'common/utils/boxPosition';
import ZingTouch from 'zingtouch';

const ATTRIBUTE_STEP = 'data-carousel-step';
const ATTRIBUTE_ACTIVE = 'data-carousel-active';
const COLOR_ATTRIBUTE = 'data-color';
const PREV_TRIGGER_ATTRIBUTE = 'data-slide-carousel-prev-trigger';
const NEXT_TRIGGER_ATTRIBUTE = 'data-slide-carousel-next-trigger';
const TRACK_ELEMENT_ATTRIBUTE = 'data-slide-carousel-track';
const CONTENT_ATTRIBUTE = 'data-slide-carousel-content';
const CONTENT_ANCHOR_TOP = 'data-slide-carousel-content-anchor-top';

export default class SlideCarousel {
    constructor(props) {
        this.captureStepClick = this.captureStepClick.bind(this);

        this.element = props.element;
        this.stepElement = props.stepElement;
        this.trackElement = this.element.querySelector(`[${TRACK_ELEMENT_ATTRIBUTE}]`);
        this.index = 1;
        this._count = null;
        this.stepClickEventMessage = props.stepClickEventMessage || null;

        if (!this.currentStepElement() || !this.currentContentElement()) {
            throw new Error('SlideCarousel requires a step and content elements');
        }

        this.listenForStepClicks();
        this.listenForPrevTriggerClick();
        this.listenForNextTriggerClick();
        this.goToStepWithoutAnchoring(this.index);

        this.initGestureRecognition();
    }

    count() {
        if (!this._count) {
            this._count = this.stepElement.querySelectorAll(`[${ATTRIBUTE_STEP}]`).length;
        }
        return this._count;
    }

    goToStepWithoutAnchoring(stepIndex) {
        const activeSteps = this.stepElement.querySelectorAll(`[${ATTRIBUTE_ACTIVE}]`);
        const contentElements = this.element.querySelectorAll(`[${CONTENT_ATTRIBUTE}]`);

        for (var i = 0; i < activeSteps.length; i++) {
            activeSteps[i].removeAttribute(ATTRIBUTE_ACTIVE);
        }

        for (var i = 0; i < contentElements.length; i++) {
            contentElements[i].removeAttribute(ATTRIBUTE_ACTIVE);
        }

        this.index = Math.abs(stepIndex) % this.count() || this.count();
        this.element.setAttribute(ATTRIBUTE_STEP, this.index);
        this.currentStepElement().setAttribute(ATTRIBUTE_ACTIVE, true);
        this.currentContentElement().setAttribute(ATTRIBUTE_ACTIVE, true);
        this.slideToStep();
        this.setCurrentColor();
    }

    goToStep(stepIndex) {
        this.goToStepWithoutAnchoring(stepIndex);
        this.anchorContent();
    }

    slideToStep() {
        this.trackElement.style.transform = `translateX(-${(
            ((this.index - 1) / this.count()) *
            100
        ).toFixed(2)}%)`;
    }

    setCurrentColor() {
        const color = this.currentContentElement().getAttribute(COLOR_ATTRIBUTE);

        if (color) {
            this.element.style.color = color;
        }
    }

    currentStepElement() {
        return this.stepElement.querySelector(`[${ATTRIBUTE_STEP}="${this.index}"]`);
    }

    currentContentElement() {
        return this.element.querySelector(
            `[${CONTENT_ATTRIBUTE}][${ATTRIBUTE_STEP}="${this.index}"]`
        );
    }

    listenForStepClicks() {
        const steps = this.stepElement.querySelectorAll(`[${ATTRIBUTE_STEP}]`);

        for (var i = 0; i < steps.length; i++) {
            steps[i].addEventListener('click', this.captureStepClick);
        }
    }

    listenForPrevTriggerClick() {
        const trigger = this.element.querySelector(`[${PREV_TRIGGER_ATTRIBUTE}]`);

        if (trigger) {
            trigger.addEventListener('click', () => {
                this.goToStep(this.index - 1);
                this.trackEventForLocation('left-button');
            });
        }
    }

    listenForNextTriggerClick() {
        const trigger = this.element.querySelector(`[${NEXT_TRIGGER_ATTRIBUTE}]`);

        if (trigger) {
            trigger.addEventListener('click', () => {
                this.goToStep(this.index + 1);
                this.trackEventForLocation('right-button');
            });
        }
    }

    captureStepClick(event) {
        let stepElement = event.target;
        let stepElementIndex = parseInt(stepElement.getAttribute(ATTRIBUTE_STEP));

        if (stepElementIndex) {
            this.goToStep(stepElementIndex);
        }

        this.trackEventForLocation('indicator-step');
    }

    static create({
        elementSelector = '[data-slide-carousel]',
        stepElementSelector = '[data-carousel-steps]',
        stepClickEventMessage = null,
    } = {}) {
        const element = document.querySelector(elementSelector);

        if (element) {
            const stepElement = element.querySelector(stepElementSelector);

            try {
                const carousel = new SlideCarousel({
                    element: element,
                    stepElement: stepElement,
                    stepClickEventMessage: stepClickEventMessage,
                });

                return carousel;
            } catch (e) {
                console.warn(e.message);
                return null;
            }
        }
    }

    trackEventForLocation(location) {
        if (this.stepClickEventMessage) {
            trackEvent(this.stepClickEventMessage, {
                location: location,
                image_index: this.index - 1, // -1 lets the index start at 0
            });
        }
    }

    anchorContent() {
        const anchorableElement = this.element.querySelector(`[${CONTENT_ANCHOR_TOP}]`);
        const isAnchorable = !!anchorableElement;
        const isConstrainedViewport = isSmallViewport() || isMediumViewport();
        if (isAnchorable && isConstrainedViewport) {
            const offsetTop = 20;
            window.scrollTo({
                top: getOffset(anchorableElement).top - offsetTop,
                behavior: 'instant',
            });
        }
    }

    initGestureRecognition() {
        const region = new ZingTouch.Region(this.element, true, false);

        region.bind(this.element, 'swipe', e => {
            const currentDirection = e.detail.data[0].currentDirection;

            // Checks that the direction is in the left half of the unit circle
            // which equates to a left swipe gesture.
            if (currentDirection >= 150 && currentDirection <= 210) {
                this.goToStep(this.index + 1);
                this.trackEventForLocation('left-swipe');
            } else if (
                (currentDirection >= 0 && currentDirection <= 30) ||
                (currentDirection >= 330 && currentDirection <= 360)
            ) {
                this.goToStep(this.index - 1);
                this.trackEventForLocation('right-swipe');
            }
        });
    }
}
